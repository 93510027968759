import { endOfMonth, format } from "date-fns";

const formatDate = (date) => format(date, "yyyy-MM-dd");

const dailyRangeDecider = (range, showDaily) => {
  if (!showDaily) return { before: undefined, after: undefined };
  const currentDate = new Date();
  const oneDayAfter = new Date();
  oneDayAfter.setDate(currentDate.getDate() + 1);

  return { before: formatDate(currentDate), after: formatDate(oneDayAfter) };
};

const upcomingRangeDecider = (range, showDaily) => {
  const lastDay = endOfMonth(range);
  const lastDayOfMonth = lastDay.setDate(lastDay.getDate() + 1);
  if (showDaily) {
    const currentDate = new Date();
    const oneDayAfter = new Date();
    oneDayAfter.setDate(currentDate.getDate() + 1);

    return {
      before: formatDate(oneDayAfter),
      after: formatDate(lastDayOfMonth),
    };
  }

  return {
    before: formatDate(range),
    after: formatDate(lastDayOfMonth),
  };
};

export const scheduledUrlDecider = (range, showDaily) => {
  if (!range) return { dailyUrl: "", upcomingUrl: "" };

  const { after: afterDaily, before: beforeDaily } = dailyRangeDecider(
    range,
    showDaily
  );
  const { after: afterUpcoming, before: beforeUpcoming } = upcomingRangeDecider(
    range,
    showDaily
  );

  const cancelDaily = !Boolean(afterDaily) && !Boolean(beforeDaily);
  const dailyUrl = cancelDaily
    ? ""
    : `/scheduled_payments?q[date_and_time_lteq]=${afterDaily}&q[date_and_time_gteq]=${beforeDaily}&q[state_eq]=waiting_for_payment&q[is_overdue_eq_any][]=nil&q[is_overdue_eq_any][]=false`;
  const upcomingUrl = `/scheduled_payments?q[date_and_time_lteq]=${afterUpcoming}&q[date_and_time_gteq]=${beforeUpcoming}&q[state_eq]=waiting_for_payment&q[is_overdue_eq_any][]=nil&q[is_overdue_eq_any][]=false`;

  return { dailyUrl, upcomingUrl };
};
