import { isEmpty } from "lodash";
import { createContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Banner } from "../../../../components/Banner";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../../components/Modals";
import { RightModal } from "../../../../components/Modals/RightModal/parent";
import PINModal from "../../../../components/PINModal/parent";
import {
  GothamMedium,
  GothamRegular,
  TextInlineBold,
} from "../../../../components/Text";
import { getUserRole } from "../../../../contexts/AuthContext";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { CompanyBalanceAndTopUp } from "../../../../modals/smartActivityModal/crossBorderSingleModal/ReleasePaymentModal";
import { SmartActivityModal } from "../../../../modals/smartActivityModal/parent";
import { fetch, useMutation } from "../../../../tools/api";
import { WrapperModalButtons } from "../../../homeComponents/topup/components";
import { spesificDateFormat } from "../../formatter";
import { CustomResultBanner } from "../../paymentOptions/modals";
import { SpesificDateForm } from "../../reschedulePayment/forms";
import { useModalHookData, useScheduleIndexModal } from "../hooks";
import {
  ModalBottomSheet,
  ModalTable,
  RescheduleButtons,
  SummaryTransactions,
} from "./component";
import {
  bannerDate,
  bannerText,
  dateUrlMaker,
  extractDetail,
  modalDataFormatter,
} from "./formatter";

export const ModalIndexContext = createContext([]);
const Content = ({
  date,
  onClickReschedule,
  onClickPay,
  onClickRow,
  isBookKeeper,
  isDelayed = false,
  hidden,
}) => {
  const title = isDelayed ? "Delayed Payment" : "Upcoming Scheduled Payment";
  const { data, openedTable, handleSee, hasMore } = useScheduleIndexModal({
    date,
  });
  const { data: tableData, defaultSummary } = data;
  const [selectedData, setSelectedData] = useState([]);

  const showButton = !isEmpty(selectedData);

  const selectedSummary = useMemo(() => {
    if (isEmpty(selectedData)) return defaultSummary;
    const datas = selectedData?.map(({ originator }) => originator);
    return extractDetail(datas);
  }, [selectedData, defaultSummary]);

  const contentHeight = showButton ? "calc(100% - 144px)" : "100%";
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          height: contentHeight,
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            padding: 16,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 32,
            height: "auto",
          }}
        >
          <GothamMedium className="font24">{title}</GothamMedium>
          <Banner msg={bannerText(date)} />
          {!hidden && (
            <ModalTable
              data={tableData}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              onClickRow={onClickRow}
              hideCheckBox={isBookKeeper}
              openedTable={openedTable}
              hasMore={hasMore}
              handleSee={handleSee}
            />
          )}
          <SummaryTransactions details={selectedSummary} />
        </div>
      </div>
      <div
        style={{
          height: 144,
          width: "100%",
        }}
      >
        {showButton && (
          <ModalBottomSheet
            onClickReschedule={() => onClickReschedule(selectedData)}
            onClickPay={() => onClickPay(selectedData)}
            totalPayment={selectedSummary?.totalPayment}
          />
        )}
      </div>
    </div>
  );
};

export const SchedulePaymentDashboardModal = ({
  isOpen,
  toggle,
  date,
  refetchIndex,
}) => {
  const isDelayed = date == "delayed_payment";

  // const [rescheduleData, setRescheduleData] = useState();
  // const isReschedule = !isEmpty(rescheduleData);
  // const onClickReschedule = (data) => setRescheduleData(data);

  const [payNowData, setPayNowData] = useState();
  const isPayNow = !isEmpty(payNowData);
  const onClickPay = (data) => setPayNowData(data);

  const {
    data: rescheduleData,
    isOpen: isReschedule,
    open: openRescheduleModal,
    close: closeRescheduleModal,
  } = useModalHookData();

  const {
    data: detailData,
    isOpen: isOpenDetail,
    open: openDetail,
    close: closeDetail,
  } = useModalHookData();

  const { successSnackBar } = ToasterHook();

  const { mutation: bulkPay, loading: loadingPay } = useMutation({
    url: "/scheduled_payments/bulk_action",
    afterSuccess: async ({ data: resData }) => {
      successSnackBar({ msg: `${resData?.data?.length} have been paid!` });
      await refetchIndex();
      setPayNowData([]);
      toggle();
    },
  });

  const { isBookKeeper } = getUserRole();
  const hidden = isReschedule || isPayNow || isOpenDetail;

  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        noPadding
        width={[480]}
        hideModal={hidden}
        array={[
          <Content
            date={date}
            isDelayed={isDelayed}
            onClickReschedule={openRescheduleModal}
            onClickPay={onClickPay}
            onClickRow={openDetail}
            isBookKeeper={isBookKeeper}
            hidden={hidden}
          />,
        ]}
      />
      <PINModal
        isLoading={loadingPay}
        isOpen={isPayNow}
        toggle={() => setPayNowData([])}
        onBack={() => setPayNowData([])}
        onSubmit={({ pin }) => {
          const ids = payNowData?.map(({ id }) => id);
          bulkPay({ pin, ids, action_for: "pay" });
        }}
      />
      <BulkRescheduleModal
        isOpen={isReschedule}
        toggle={closeRescheduleModal}
        data={rescheduleData}
        afterSuccess={async () => {
          await refetchIndex();
          toggle();
          closeRescheduleModal();
        }}
      />
      <SmartActivityModal
        isOpen={isOpenDetail}
        toggle={closeDetail}
        data={detailData}
      />
    </>
  );
};

const BulkRescheduleContent = ({
  tableData,
  toggle,
  onClickSubmit,
  isSingleButton = false,
}) => {
  const [selectedData, setSelectedData] = useState([]);

  const [dateAndTime, setDateAndTime] = useState();
  const disableSubmit = isEmpty(dateAndTime);

  const { date_and_time } = dateAndTime || {};

  const text = spesificDateFormat(date_and_time);

  useEffect(() => {
    if (isEmpty(selectedData)) return setSelectedData(tableData);
  }, [selectedData]);
  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          padding: 16,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 32,
          height: "calc(100% - 80px)",
          overflowY: "auto",
        }}
      >
        <GothamMedium className="font24">Reschedule Payment</GothamMedium>
        <Banner title="Select a new date & time to reschedule the payment of these transactions." />
        <ModalTable
          data={tableData}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          hideArrow={true}
          showPrevious={true}
        />
        <div className="d-flex-column">
          <GothamMedium style={{ marginBottom: 16 }}>
            Select new specific date & time
          </GothamMedium>
          <SpesificDateForm
            setItem={setDateAndTime}
            containerStyle={{ paddingLeft: 0 }}
            inputStyle={{ width: 208 }}
          />
        </div>
        {!disableSubmit && (
          <div>
            <GothamRegular className="font12" style={{ marginBottom: 4 }}>
              New Payment Schedule
            </GothamRegular>
            <CustomResultBanner text={text} showResult />
          </div>
        )}
      </div>
      <RescheduleButtons
        isSingleButton={isSingleButton}
        onClickBack={toggle}
        disableReschedule={disableSubmit}
        onClickReschedule={() => {
          const ids = selectedData?.map(({ id }) => id);
          const { date_and_time } = dateAndTime;
          onClickSubmit({ date_and_time, ids });
        }}
      />
    </div>
  );
};

export const BulkRescheduleModal = ({
  data,
  isOpen = false,
  toggle,
  afterSuccess,
  isSingleButton = false,
}) => {
  const [payload, setPayload] = useState({});
  const { mutation: bulkReschedule, loading } = useMutation({
    url: "/scheduled_payments/bulk_action",
    afterSuccess: (res) => {
      afterSuccess();
      setPayload({});
    },
  });
  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        noPadding
        width={[480]}
        hideModal={!isEmpty(payload)}
        array={[
          <BulkRescheduleContent
            tableData={data}
            toggle={toggle}
            onClickSubmit={(v) => setPayload(v)}
            isSingleButton={isSingleButton}
          />,
        ]}
      />
      <PINModal
        isOpen={!isEmpty(payload)}
        isLoading={loading}
        toggle={() => setPayload({})}
        onBack={() => setPayload({})}
        onSubmit={({ pin }) => {
          bulkReschedule({ pin, ...payload, action_for: "reschedule" });
        }}
      />
    </>
  );
};

const BulkPayContent = ({ tableData = [], toggle, onClickSubmit }) => {
  // const [selectedData, setSelectedData] = useState([]);

  // const [dateAndTime, setDateAndTime] = useState();
  const disableSubmit = false;

  // const { date_and_time } = dateAndTime || {};

  // const text = spesificDateFormat(date_and_time);

  // useEffect(() => {
  //   if (isEmpty(selectedData)) return setSelectedData(tableData);
  // }, [selectedData]);

  const selectedSummary = useMemo(() => {
    if (isEmpty(tableData)) return {};
    const datas = tableData?.map(({ originator }) => originator);
    return extractDetail(datas);
  }, [tableData]);

  const { totalPayment } = selectedSummary || {};
  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          padding: 16,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 32,
          height: "calc(100% - 144px)",
          overflowY: "auto",
        }}
      >
        <GothamMedium className="font24">Pay Immediately</GothamMedium>
        <Banner msg="You're about to make an immediate payment. Make sure you've selected the correct transactions." />
        <ModalTable
          data={tableData}
          selectedData={[]}
          setSelectedData={null}
          hideCheckBox={true}
          isBulkPay={true}
          hideArrow
        />
        <SummaryTransactions details={selectedSummary} />
      </div>
      <ModalBottomSheet
        isOnlyPay
        onClickPay={() => {
          const ids = tableData?.map(({ id }) => id);
          onClickSubmit({ ids });
        }}
        totalPayment={totalPayment}
      />
    </div>
  );
};

export const BulkPayImmediatelyModal = ({
  data,
  isOpen = false,
  toggle,
  afterSuccess,
}) => {
  const [payload, setPayload] = useState({});
  const { mutation: bulkReschedule, loading } = useMutation({
    url: "/scheduled_payments/bulk_action",
    afterSuccess: ({ data: resData }) => {
      afterSuccess();
      setPayload({});
    },
  });
  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        noPadding
        width={[480]}
        hideModal={!isEmpty(payload)}
        array={[
          <BulkPayContent
            tableData={data}
            toggle={toggle}
            onClickSubmit={(v) => setPayload(v)}
          />,
        ]}
      />
      <PINModal
        isOpen={!isEmpty(payload)}
        isLoading={loading}
        toggle={() => setPayload({})}
        onBack={() => setPayload({})}
        onSubmit={({ pin }) =>
          bulkReschedule({ pin, ...payload, action_for: "pay" })
        }
      />
    </>
  );
};
