import { format } from "date-fns";
import { isEmpty } from "lodash";
import { Fragment, useContext, useEffect, useState } from "react";
import ViewportList from "react-viewport-list";
import { ModalIndexContext } from ".";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { Avatar } from "../../../../components/Avatar";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { StatusAccountIconJack } from "../../../../components/Status/JackStatusIcon";
import { DropdownTableSimple } from "../../../../components/Table/dropdown/parent";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../../components/tools";
import { TransitionTr } from "../../../../components/Transition";
import { useGetAuth } from "../../../../contexts/AuthContext";
import { CompanyBalanceAndTopUp } from "../../../../modals/smartActivityModal/crossBorderSingleModal/ReleasePaymentModal";
import CheckboxSquare from "../../../alertsAndAttentionComponents/components/CheckboxSquare";
import { scheduleTableDesc } from "../../../dashboardComponents/scheduled/components";
import {
  idrStringFormatter,
  pluralize,
} from "../../../invoiceComponents/data/formatter";

export const ModalTable = ({
  data: tableDatas = [],
  selectedData,
  setSelectedData,
  onClickRow,
  hideCheckBox,
  openedTable,
  hasMore,
  hideArrow,
  showPrevious,
  isBulkPay = false,
  handleSee,
}) => {
  const isSelectedAll = hideCheckBox
    ? false
    : selectedData?.length !== 0 && selectedData?.length == tableDatas?.length;
  const header = [
    !hideCheckBox && {
      label: (
        <CheckboxSquare
          isChecked={isSelectedAll}
          onClick={() => {
            setSelectedData((p) => {
              if (isSelectedAll) return [];
              return tableDatas;
            });
          }}
        />
      ),
      width: "9%",
      textAlign: "Description",
    },
    {
      label: "Description",
      width: "75%",
    },
    {
      label: "Amount",
      width: "37%",
    },
    !(hideArrow || isBulkPay) && {
      label: "",
      width: "9%",
      textAlign: "left",
    },
  ];

  if (!tableDatas?.length) return null;
  return (
    <DropdownTableSimple
      headerTable={header}
      headerStyles={{ height: 0, border: "" }}
      dropdownProps={{
        name: "index",
        isOpen: openedTable,
        hasMoreForced: hasMore,
        setIsOpen: handleSee,
      }}
      // isAutoHeight
      bodyComponent={
        <Fragment>
          <ViewportList
            margin={8}
            overscan={10}
            itemMinSize={100}
            items={tableDatas}
          >
            {(tableData, index) => {
              const isActive = hideCheckBox
                ? false
                : (selectedData || [])?.some(({ id }) => id == tableData?.id);
              return (
                <TableRow
                  onClickRow={onClickRow}
                  data={tableData}
                  index={index}
                  isActive={isActive}
                  hideCheckBox={hideCheckBox}
                  hideArrow={hideArrow}
                  showPrevious={showPrevious}
                  isBulkPay={isBulkPay}
                  onClickCheckbox={() => {
                    if (isActive)
                      return setSelectedData((p) =>
                        p?.filter(({ id }) => id != tableData?.id)
                      );
                    return setSelectedData((p) => [...p, tableData]);
                  }}
                />
              );
            }}
          </ViewportList>
        </Fragment>
      }
    />
  );
};

export const clockAndTime = (dat) => {
  const date = new Date(dat);
  return format(date, "'🕐' HH:mm 'WIB'");
};

const textStyle = {
  width: "calc(100% - 16px)",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

const TableRow = ({
  data,
  index,
  isActive,
  onClickCheckbox,
  onClickRow,
  hideCheckBox,
  hideArrow = false,
  showPrevious = false,
  isBulkPay = false,
}) => {
  const { originator, originator_type, type, date_and_time } = data;

  const isLocal = originator_type == "LocalTransactionBatch";
  const isInvoice = originator_type == "InvoiceTransaction";
  const isReimbursement = originator_type == "Reimbursement";
  const isPayroll = originator_type == "Payroll";

  const description = scheduleTableDesc(originator, type);

  const { amount, total_amount, category } = originator;
  const idr = idrStringFormatter(total_amount);

  const dat = new Date(date_and_time);
  const previousDate = format(dat, "'Previous: ' dd/MM/yyyy (HH:mm 'WIB')");
  const hnm = clockAndTime(date_and_time);
  const bulkPayText = format(dat, "'Scheduled: ' dd/MM/yyyy (HH:mm 'WIB')");
  const textBottom = isBulkPay
    ? bulkPayText
    : showPrevious
    ? previousDate
    : hnm;
  return (
    <TransitionTr perPage={10} onClick={() => onClickRow(originator)}>
      {!hideCheckBox && (
        <td>
          <CheckboxSquare isChecked={isActive} onClick={onClickCheckbox} />
        </td>
      )}

      <td
        style={{
          display: "flex",
          gap: 16,
          alignItems: "center",
          padding: "12px 8px",
        }}
      >
        <StatusAccountIconJack state={category} />
        <div
          className="d-flex-column justify-content-between"
          style={{ width: "92%" }}
        >
          <GothamRegular style={textStyle}>{description}</GothamRegular>
          <GothamRegular
            className="font12"
            style={{ color: isBulkPay ? "#909098" : "#343434" }}
          >
            {textBottom}
          </GothamRegular>
        </div>
      </td>

      <td>
        <GothamRegular style={textStyle}>{idr}</GothamRegular>
      </td>

      {!hideArrow && (
        <td>
          <JackIcons name="chevron_right" fill="#BBBBC0" />
        </td>
      )}
    </TransitionTr>
  );
};

export const ModalBottomSheet = ({
  onClickReschedule,
  onClickPay,
  disablePay,
  isOnlyPay = false,
  totalPayment,
  containerStyle,
}) => {
  const { user, refetchUser, userLoading } = useGetAuth();
  const { mainBalance } = user;
  const { balance } = mainBalance;
  const companyBalanceIdr = `IDR ${formatCurrencyNoDecimal(balance)}`;

  const remainingBalance = balance - totalPayment;
  const isSufficient = remainingBalance >= 0 ? true : false;

  const disablePaybutton = disablePay || !isSufficient;
  return (
    <div
      style={{
        width: "100%",
        height: 144,
        ...containerStyle,
      }}
    >
      <CompanyBalanceAndTopUp
        companyBalanceIdr={companyBalanceIdr}
        refresh={refetchUser}
        loading={userLoading}
        style={{ position: "relative", top: 0 }}
      />
      {isOnlyPay ? (
        <div
          style={{
            height: 80,
            display: "flex",
            alignItems: "center",
            padding: 20,
          }}
        >
          <ButtonJack
            className="w-100"
            onClick={onClickPay}
            disabled={disablePaybutton}
            leftIcon={
              <JackIcons
                name="checkmark_circle_2"
                fill={disablePaybutton ? "#909098" : "#343434"}
              />
            }
          >
            Pay immediately
          </ButtonJack>
        </div>
      ) : (
        <div
          style={{
            height: 80,
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: 20,
          }}
        >
          <div className="d-flex" style={{ gap: 16 }}>
            <ButtonJack
              type="outline"
              onClick={onClickReschedule}
              leftIcon={<JackIcons name="reschedule_fill" fill="#343434" />}
            >
              Reschedule
            </ButtonJack>
            <ButtonJack
              onClick={onClickPay}
              disabled={disablePaybutton}
              leftIcon={
                <JackIcons
                  name="checkmark_circle_2"
                  fill={disablePaybutton ? "#909098" : "#343434"}
                />
              }
            >
              Pay immediately
            </ButtonJack>
          </div>
        </div>
      )}
    </div>
  );
};

export const RescheduleButtons = ({
  onClickBack,
  onClickReschedule,
  disableReschedule,
  isSingleButton = false,
}) => {
  if (isSingleButton) {
    return (
      <div style={{ height: 80, padding: 20, borderTop: "1px solid #e6e6e8" }}>
        <ButtonJack
          style={{ width: "100%" }}
          onClick={onClickReschedule}
          disabled={disableReschedule}
          leftIcon={
            <JackIcons
              name="reschedule_fill"
              fill={disableReschedule ? "#BBBBC0" : "#343434"}
            />
          }
        >
          Reschedule
        </ButtonJack>
      </div>
    );
  }

  return (
    <div style={{ height: 80, padding: 20, borderTop: "1px solid #e6e6e8" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <div className="d-flex" style={{ gap: 16 }}>
          <ButtonJack type="outline" onClick={onClickBack}>
            Back
          </ButtonJack>
          <ButtonJack onClick={onClickReschedule} disabled={disableReschedule}>
            Reschedule
          </ButtonJack>
        </div>
      </div>
    </div>
  );
};

const LeftRight = ({
  isIcon = false,
  isDot = false,
  isGreyText = false,
  isBold = false,
  leftText,
  rightText,
}) => {
  const Text = isBold ? GothamMedium : GothamRegular;
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center" style={{ gap: 8 }}>
        {isIcon && (
          <JackIcons
            style={{ height: 16, width: 16 }}
            name="plus_circle"
            fill="#e6e6e8"
          />
        )}
        {isDot && (
          <div
            style={{
              height: 6,
              width: 6,
              borderRadius: 100,
              backgroundColor: "#e6e6e8",
              margin: 5,
            }}
          />
        )}
        <Text
          className="font12"
          style={{ color: isGreyText ? "#909098" : "#343434" }}
        >
          {leftText}
        </Text>
      </div>
      <Text className="font12">{rightText}</Text>
    </div>
  );
};
export const SummaryTransactions = ({ details }) => {
  const [showDetails, setShow] = useState(false);

  const {
    totalTransactions,
    totalAmount,
    totalFee,
    details: detailFee,
    totalPayment,
  } = details || {};

  return (
    <div
      style={{
        padding: 12,
        display: "flex",
        flexDirection: "column",
        gap: 12,
        border: "1px solid #e6e6e8",
        borderRadius: 4,
      }}
    >
      <GothamMedium>Summary</GothamMedium>
      <div style={{ borderTop: "1px solid #e6e6e8" }} />
      <LeftRight
        leftText="Selected transaction"
        rightText={totalTransactions}
      />
      <LeftRight
        leftText="Total amount"
        rightText={idrStringFormatter(totalAmount)}
      />
      <LeftRight
        leftText="Total fee"
        rightText={idrStringFormatter(totalFee)}
        isIcon={true}
      />
      <div
        style={{
          paddingLeft: 20,
          gap: 12,
          display: "flex",
          flexDirection: "column",
          gap: 12,
        }}
      >
        {showDetails &&
          detailFee?.map((detail) => {
            const { rightText: old, ...rest } = detail;
            const rightText = idrStringFormatter(old);
            const data = { rightText, ...rest };
            return <LeftRight {...data} />;
          })}
        <div
          className="d-flex align-items-center"
          style={{ cursor: "pointer" }}
        >
          <GothamMedium
            onClick={() => setShow((p) => !p)}
            className="font12"
            style={{ textDecorationLine: "underline", color: "#909098" }}
          >
            {showDetails ? "Hide" : "View"} details
          </GothamMedium>
          <JackIcons
            name={showDetails ? "chevron_up" : " chevron_down"}
            fill="#909098"
            style={{ transform: "scale(0.6667)" }}
          />
        </div>
      </div>
      <div style={{ borderTop: "1px solid #e6e6e8" }} />
      <LeftRight
        leftText="Total payment"
        rightText={idrStringFormatter(totalPayment)}
        isBold
      />
    </div>
  );
};
