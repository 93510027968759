import { format } from "date-fns";
import { isEmpty, times } from "lodash";
import { Fragment, useEffect, useMemo, useState } from "react";
import ViewportList from "react-viewport-list";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { SkeletonTd } from "../../../components/Shimmer";
import { booleanState } from "../../../components/Status";
import { StatusAccountIconJack } from "../../../components/Status/JackStatusIcon";
import { DropdownTableSimple } from "../../../components/Table/dropdown/parent";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { TransitionTr } from "../../../components/Transition";
import { SmartActivityModal } from "../../../modals/smartActivityModal/parent";
import CheckboxSquare from "../../alertsAndAttentionComponents/components/CheckboxSquare";
import { idrStringFormatter } from "../../invoiceComponents/data/formatter";
import { useModalHookData } from "../../scheduledPaymentComponents/dashboardComponent/hooks";
import {
  BulkPayImmediatelyModal,
  BulkRescheduleModal,
} from "../../scheduledPaymentComponents/dashboardComponent/modals.js";
import { clockAndTime } from "../../scheduledPaymentComponents/dashboardComponent/modals.js/component";
import { extractDetail } from "../../scheduledPaymentComponents/dashboardComponent/modals.js/formatter";
import { useScheduleIndexData } from "./hooks";

const Menu = ({ label, isActive = false, number, onClick }) => {
  const Text = isActive ? GothamMedium : GothamRegular;
  const color = isActive ? "#343434" : "#909098";
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        padding: "0px 8px",
        height: 40,
        borderBottom: isActive && "2px solid #343434",
      }}
      onClick={onClick}
    >
      <div style={{ display: "flex", gap: 8 }}>
        <Text style={{ color }}>{label}</Text>
        {number >= 0 && (
          <div
            style={{
              height: 20,
              width: 24,
              borderRadius: 4,
              backgroundColor: "#F26727",
              display: "grid",
              placeItems: "center",
            }}
          >
            <GothamMedium className="font12 text-white">{number}</GothamMedium>
          </div>
        )}
      </div>
    </div>
  );
};

export const IndexHeader = ({
  onClickBack,
  onClickUpcoming,
  onClickDelayed,
  delayedCount,
  isUpcoming,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 24,
        borderBottom: "1px solid #e6e6e8",
      }}
    >
      <div
        className="d-flex align-items-center"
        style={{ gap: 16, cursor: "pointer" }}
        onClick={onClickBack}
      >
        <JackIcons name="arrow_back_outline" fill="#343434" />
        <GothamRegular>Back</GothamRegular>
      </div>
      <GothamMedium className="font24">Scheduled payment</GothamMedium>
      <div className="d-flex" style={{ gap: 8 }}>
        <Menu
          label="Upcoming"
          isActive={isUpcoming}
          onClick={onClickUpcoming}
        />
        <Menu
          label="Delayed"
          isActive={!isUpcoming}
          number={delayedCount}
          onClick={onClickDelayed}
        />
      </div>
    </div>
  );
};

export const MonthNextPrev = ({
  date,
  disablePrev,
  onClickPrev,
  disableNext,
  onClickNext,
}) => {
  const text = Boolean(date) ? format(date, "MMM yyyy") : "";
  return (
    <div className="d-flex align-items-center" style={{ gap: 12 }}>
      <GothamMedium className="font16">{text}</GothamMedium>
      <JackIcons
        name="arrow_circle_left_outline"
        fill={disablePrev ? "#BBBBC0" : "#343434"}
        onClick={() => {
          if (disablePrev) return;
          onClickPrev();
        }}
        className={!disablePrev && "iconHover"}
      />
      <JackIcons
        name="arrow_circle_right_outline"
        fill={disableNext ? "#BBBBC0" : "#343434"}
        onClick={onClickNext}
        className={!disableNext && "iconHover"}
      />
    </div>
  );
};

export const ScheduledTable = ({
  isToday,
  range,
  showDaily,
  isDelayed,
  setDataCount,
  refetchIndex,
  setActive,
  id,
  canCheck,
}) => {
  const {
    data: dataRaw,
    loading,
    hasMore,
    refetch,
    openedTable,
    handleSee,
  } = useScheduleIndexData({
    isToday: isToday,
    range,
    showDaily,
    isDelayed: isDelayed,
  });
  const { data, defaultSummary, total_schedules } = dataRaw || {};

  const { totalPayment } = defaultSummary || {};

  const tableWidth = (number) => `${(number / 1120) * 100}%`;
  const [selectedData, setSelectedData] = useState([]);
  const isSelectedAll =
    total_schedules !== 0 && selectedData?.length === total_schedules;
  const header = [
    {
      label: (
        <CheckboxSquare
          isChecked={isSelectedAll}
          onClick={() => {
            if (!canCheck) return;
            setSelectedData(() => {
              if (isSelectedAll) {
                return [];
              }
              return data;
            });
          }}
        />
      ),
      width: tableWidth(40),
      textAlign: "left",
    },
    {
      label: isDelayed ? "Previous Schedule" : "Scheduled on",
      width: tableWidth(124),
      textAlign: "left",
    },
    {
      label: "Description",
      width: tableWidth(540),
      textAlign: "left",
    },
    {
      label: "Amount",
      width: tableWidth(188),
      textAlign: "right",
    },
    {
      label: "",
      width: tableWidth(40),
      textAlign: "right",
    },
  ];

  const isTableEmpty = isEmpty(data);

  const showBottomSheet = !isEmpty(selectedData);

  useEffect(() => {
    if (isEmpty(selectedData)) return setActive("");
    return setActive((p) => {
      if (p == id) return;
      return id;
    });
  }, [selectedData]);

  const {
    data: rescheduleData,
    isOpen: isReschedule,
    open: openRescheduleModal,
    close: closeRescheduleModal,
  } = useModalHookData();
  const {
    data: payNowData,
    isOpen: isPayNow,
    open: openPayNow,
    close: closePayNow,
  } = useModalHookData();

  const {
    data: detailData,
    isOpen: isDetail,
    open: openDetail,
    close: closeDetail,
  } = useModalHookData();

  if (isTableEmpty) return null;

  return (
    <>
      <DropdownTableSimple
        headerTable={header}
        leftComponent={
          <TopComponentTable
            isToday={isToday}
            isDelayed={isDelayed}
            total_amount={totalPayment}
          />
        }
        dropdownProps={{
          name: "index",
          isOpen: openedTable,
          hasMoreForced: hasMore,
          setIsOpen: handleSee,
        }}
        // isAutoHeight
        bodyComponent={
          <Fragment>
            <ViewportList
              margin={8}
              overscan={10}
              itemMinSize={100}
              items={data}
            >
              {(transaction, index) => {
                const isActive = selectedData?.some(
                  ({ id }) => id == transaction?.id
                );
                return (
                  <TableRow
                    isActive={isActive}
                    key={transaction.id}
                    transaction={transaction}
                    index={index}
                    onClickRow={openDetail}
                    isDelayed={isDelayed}
                    onClickCheckbox={() => {
                      if (!canCheck) return;
                      if (isActive)
                        return setSelectedData((p) =>
                          p?.filter(({ id }) => id != transaction?.id)
                        );
                      return setSelectedData((p) => [...p, transaction]);
                    }}
                  />
                );
              }}
            </ViewportList>

            <LoadingTable isLoading={loading} />
          </Fragment>
        }
      />

      {showBottomSheet && (
        <BottomSheet
          data={selectedData}
          onClickReschedule={openRescheduleModal}
          onClickPay={openPayNow}
          setActive={setActive}
        />
      )}

      <BulkRescheduleModal
        isOpen={isReschedule}
        toggle={closeRescheduleModal}
        data={rescheduleData}
        isSingleButton
        afterSuccess={async () => {
          closeRescheduleModal();
          await refetch();
        }}
      />

      <BulkPayImmediatelyModal
        isOpen={isPayNow}
        toggle={closePayNow}
        data={payNowData}
        afterSuccess={async () => {
          closePayNow();
          await refetch();
        }}
      />

      <SmartActivityModal
        data={detailData}
        isOpen={isDetail}
        toggle={closeDetail}
      />
    </>
  );
};

export const scheduleTableDesc = (originator) => {
  const { name, merchant, recipient_name, type } = originator;
  const { isInvoice, isReimbursement, isLocalDisbursementBatch, isPayroll } =
    booleanState(type);

  if (isInvoice) return `Bill Payment to ${recipient_name}`;
  if (isReimbursement) return `Reimbursement: ${name || merchant}`;
  if (isPayroll) return name;
  if (isLocalDisbursementBatch) return `Local Transfer: ${name}`;

  return "";
};

const TableRow = ({
  index = 0,
  transaction = {},
  isActive,
  onClickCheckbox,
  onClickRow,
  isDelayed = false,
}) => {
  const { originator, date_and_time, originator_type } = transaction;
  const { total_amount, type } = originator;
  const time = format(new Date(date_and_time), "dd/MM/yy");
  const idr = idrStringFormatter(total_amount);

  return (
    <TransitionTr
      isQuick
      perPage={5}
      counter={index}
      style={{ cursor: "pointer" }}
      onClick={() => onClickRow(originator)}
    >
      <td>
        <CheckboxSquare isChecked={isActive} onClick={onClickCheckbox} />
      </td>

      <td>
        <div className="d-flex-column justify-content-between">
          <GothamRegular>{time}</GothamRegular>
          <GothamRegular className="font12">
            {clockAndTime(date_and_time)}
          </GothamRegular>
        </div>
      </td>

      {/* <td
        style={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <StatusAccountIconJack state={type} />
        <GothamRegular style={{ color: jackColors.neutral900 }}>
          {scheduleTableDesc(originator, type)}
        </GothamRegular>
      </td> */}

      <td
        style={{
          display: "flex",
          gap: 16,
          alignItems: "center",
          padding: "12px 8px",
          width: "calc(100% - 16px)",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        <StatusAccountIconJack state={type} />
        <div className="d-flex-column justify-content-between">
          <GothamRegular>{scheduleTableDesc(originator, type)}</GothamRegular>
          {false && <GothamRegular className="font12">{""}</GothamRegular>}
        </div>
      </td>

      <td style={{ textAlign: "right" }}>
        <GothamRegular style={{ color: jackColors.neutral900 }}>
          {idr}
        </GothamRegular>
      </td>

      <td>
        <JackIcons name="chevron-right" fill={jackColors.neutral600} />
      </td>
    </TransitionTr>
  );
};

const LoadingTable = ({ isLoading = false }) => {
  if (!isLoading) return null;

  return (
    <Fragment>
      {times(5, (index) => (
        <tr key={index}>
          {times(5, (index) => (
            <SkeletonTd key={index} />
          ))}
        </tr>
      ))}
    </Fragment>
  );
};

const TopComponentTable = ({ isToday, date, total_amount, isDelayed }) => {
  const today = new Date();
  const todayTitle = format(today, "'Today' (dd MMMM yyyy)");
  const upComingTitle = format(today, "'Upcoming' (MMMM yyyy)");

  const title = isDelayed
    ? "Delayed Payment"
    : isToday
    ? todayTitle
    : upComingTitle;
  const totalAmountIdr = idrStringFormatter(total_amount);
  return (
    <div className="d-flex align-items-center" style={{ gap: 8 }}>
      <JackIcons
        name="calendar"
        fill="#343434"
        style={{ width: 40, height: 20, padding: "0px 10px" }}
      />
      <GothamMedium>{title}</GothamMedium>
      <div
        style={{
          height: 4,
          width: 4,
          borderRadius: 100,
          backgroundColor: "#6c6c71",
        }}
      />
      <div
        style={{
          height: 20,
          padding: "0px 8px",
          backgroundColor: isDelayed ? "#F26727" : "#343434",
          borderRadius: 4,
          display: "grid",
          placeItems: "center",
        }}
      >
        <GothamMedium className="font12 text-white">
          Total : {totalAmountIdr}
        </GothamMedium>
      </div>
    </div>
  );
};

const TopDown = ({ title, text }) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 8,
      }}
    >
      <GothamRegular className="font12 text-white">{title}</GothamRegular>
      <GothamMedium className="font16 text-white">{text}</GothamMedium>
    </div>
  );
};

export const BottomSheet = ({ data, onClickReschedule, onClickPay }) => {
  const selectedSummary = useMemo(() => {
    if (isEmpty(data)) return {};
    const datas = data?.map(({ originator }) => originator);
    return extractDetail(datas);
  }, [data]);

  const { totalAmount, totalTransactions } = selectedSummary;
  return (
    <div
      style={{
        position: "fixed",
        width: "calc(100% - 240px)",
        height: 80,
        backgroundColor: "transparent",
        right: 0,
        bottom: 24,
        display: "flex",
        justifyContent: "center",
        zIndex: 999,
      }}
    >
      <div
        style={{
          width: "75%",
          height: "100%",
          backgroundColor: "#343434",
          borderRadius: 8,
          padding: "12px 24px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="d-flex" style={{ gap: 20 }}>
          <TopDown title="Selected of Transaction" text={totalTransactions} />
          <div
            style={{ height: 48, width: 1, borderLeft: "1px solid white" }}
          />
          <TopDown
            title="Total Amount"
            text={idrStringFormatter(totalAmount)}
          />
        </div>
        <div className="d-flex" style={{ gap: 20 }}>
          <ButtonJack
            classNameText="text-white"
            type="outline"
            style={{ backgroundColor: "transparent" }}
            onClick={() => onClickReschedule(data)}
            leftIcon={<JackIcons name="reschedule_fill" fill="#e6e6e8" />}
          >
            Reschedule
          </ButtonJack>
          <ButtonJack
            onClick={() => onClickPay(data)}
            leftIcon={<JackIcons name="checkmark_circle_2" fill="#343434" />}
          >
            Pay immediately
          </ButtonJack>
        </div>
      </div>
    </div>
  );
};
