import {
  addMonths,
  format,
  isSameMonth,
  isSameYear,
  startOfMonth,
} from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { fetch } from "../../../tools/api";
import { gmt7 } from "../../reimbursementComponents/data";
import { modalDataFormatter } from "../../scheduledPaymentComponents/dashboardComponent/modals.js/formatter";
import { taskResultFormatter } from "../../taskComponents/logics/formatters";
import { scheduledUrlDecider } from "./formatter";

const dateUrlMaker = (type) => {
  if (!type || isEmpty(type)) return "";
  const isToday = type == "today";
  const isUpcoming = type == "upcoming";
  const isdelayed = type == "delayed";

  const formatDate = (date) => format(date, "yyyy-MM-dd");
  if (isdelayed)
    return "/scheduled_payments?q[state_eq]=waiting_for_payment&q[is_overdue_eq]=true";

  if (isToday) {
    const today = formatDate(new Date());

    const inputDate = new Date(gmt7(today));
    const oneDayAfter = new Date(gmt7(today));
    oneDayAfter.setDate(inputDate.getDate() + 1);

    // Format the results as yyyy-MM-dd
    const before = formatDate(today);
    const after = formatDate(oneDayAfter);

    return `/scheduled_payments?q[date_and_time_lteq]=${after}&q[date_and_time_gteq]=${before}&q[state_eq]=waiting_for_payment&q[is_overdue_eq_any][]=nil&q[is_overdue_eq_any][]=false`;
  }

  if (isUpcoming) {
    const today = formatDate(new Date());

    const inputDate = new Date(gmt7(today));
    const oneDayAfter = new Date(gmt7(today));
    oneDayAfter.setDate(inputDate.getDate() + 1);
  }
};

export const useGetPageCount = ({ range, isDelayed }) => {
  const { upcomingUrl } = scheduledUrlDecider(range, false);

  const delayedUrl =
    "/scheduled_payments?q[state_eq]=waiting_for_payment&q[is_overdue_eq]=true";
  const pageQuery = `&page=1&per_page=1`;
  const url = isDelayed ? delayedUrl + pageQuery : upcomingUrl + pageQuery;

  const { data, refetch, loading } = fetch({
    url,
    formatter: ({ total_schedules }) => total_schedules,
    woInit: true,
  });

  const isEmpty = !loading && data == 0;
  useEffect(refetch, [range]);
  return {
    count: data,
    isEmpty,
    loading,
    refetch,
  };
};

export const useScheduleIndexData = ({
  isToday = false,
  showDaily = false,
  range,
  isDelayed = false,
}) => {
  const [page, setPage] = useState(1);
  const [openedTable, setOpenedTable] = useState("");

  const { dailyUrl, upcomingUrl } = scheduledUrlDecider(range, showDaily);
  const urlRaw = isToday ? dailyUrl : upcomingUrl;
  const pageQuery = `&page=${page}&per_page=5`;
  const delayedUrl =
    "/scheduled_payments?q[state_eq]=waiting_for_payment&q[is_overdue_eq]=true";
  const url = isDelayed ? delayedUrl + pageQuery : urlRaw + pageQuery;

  const { data, refetch, loading, setData } = fetch({
    url,
    formatter: taskResultFormatter,
    woInit: true,
  });

  useEffect(refetch, [page]);
  useEffect(() => {
    setData({});
    refetch();
  }, [range]);

  const seeMore = () => {
    setOpenedTable("index");
    setPage((prev) => prev + 1);
  };

  const seeLess = () => {
    setData({});
    setPage(1);
    setOpenedTable("");
  };

  const totalRefetch = async () => {
    setData({});
    setPage(1);

    await refetch();
  };

  const handleSee = (name) => {
    if (!name) return seeLess();
    return seeMore();
  };

  const { total_schedules, data: schedules } = data || {};
  const hasMore = (schedules ?? []).length < total_schedules;

  const formattedData = modalDataFormatter(data);
  return {
    data: formattedData,
    refetch: totalRefetch,
    loading,
    openedTable,
    seeLess,
    seeMore,
    hasMore,
    handleSee,
  };
};

export const useGetFirstDateOfMonth = () => {
  const [range, setRange] = useState("");
  const today = new Date();

  const isCurrentMonth = isSameMonth(range, today);
  const isCurrentYear = isSameYear(range, today);
  const showDaily = isCurrentMonth && isCurrentYear;

  const onNext = useCallback(() => {
    setRange((prevDate) => startOfMonth(addMonths(prevDate, 1)));
  }, []);

  const onPrev = useCallback(() => {
    setRange((prevDate) => startOfMonth(addMonths(prevDate, -1)));
  }, []);
  useEffect(() => setRange(startOfMonth(new Date())), []);

  return { range, showDaily, disablePrev: isCurrentMonth, onNext, onPrev };
};
